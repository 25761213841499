/*
 * @Author: zhoupengfei03
 * @Description: 控制台相关工具
 */
import packageDetail from '../../package.json';

export function consoleAppDetail() {
    try {
        const {name = 'vistudio', version = '1.0.2'} = packageDetail || {};
        console.log(
            `%c ${name} %c v${version} `,
            'color:#fff;background-color:blue;line-height:20px;border-radius: 5px 0 0 5px',
            'color:#fff;background-color:orange;line-height:20px;border-radius: 0 5px 5px 0');
    } catch (error) {
        console.log(error);
    }
}