/*
 * @Author: zhoupengfei03
 * @Date: 2021-12-27 17:14:23
 * @LastEditTime: 2023-11-09 18:03:44
 * @LastEditors: liuyan45
 * @FilePath: /aiqa-fe-labelset/src/layout/index.tsx
 * @Description: 项目布局配置
 */

/* eslint-disable react/jsx-key */
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, withRouter} from 'react-router-dom';

import {updateHeaderStatus, updateSideNavStatus} from '@/redux/menu.slice';

import {getTargetRouteConfig} from '../routes';

import AppMain from './components/AppMain';

export interface UserDetailProps {
    name?: string;
}

const MainPage = props => {
    const location = useLocation();
    const dispatch = useDispatch();

    // 路由切换，根据路由配置更新侧导航和顶部导航显示隐藏状态
    useEffect(
        () => {
            const targetRouterConfig = getTargetRouteConfig(location.pathname) || {};
            dispatch(updateHeaderStatus(!!targetRouterConfig.hideHeaderNav));
            dispatch(updateSideNavStatus(!!targetRouterConfig.hideSideNav));
        },
        [location.pathname]
    );

    return (
        <AppMain />
    );
};

export default withRouter(MainPage);