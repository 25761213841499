/*
 * @Author: zhoupengfei03
 * @Date: 2023-07-12 10:43:17
 * @LastEditors: liuyan45
 * @LastEditTime: 2023-11-10 15:41:19
 * @Description: 入口文件toastDom
 */

import {ConfigProvider} from 'acud';
import ReactDOM from 'react-dom/client';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import zhCN from 'acud/lib/locale/zh_CN';

import {consoleAppDetail} from '@/utils';
import '@/utils/flexible';
import '@/styles';
import {store} from '@/redux';
import PrimaryLayout from '@/layout/index';

const root = ReactDOM.createRoot(document.getElementById('yijian-home-app'));

/**
 * 渲染页面
 */
const renderApp = () => {
    root.render(
        <ReduxProvider store={store}>
            <ConfigProvider locale={zhCN}>
                <Router>
                    <PrimaryLayout />
                </Router>
            </ConfigProvider>
        </ReduxProvider>
    );
    /**
     * 打印app基本信息
     */
    consoleAppDetail();
};

renderApp();