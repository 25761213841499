/*
 * @Author: zhoupengfei03
 * @Description: 一见官网埋点
 */
import {getCookie} from './cookie';

export function feMonitor() {
    try {
        const scriptNode = document.createElement('script');
        scriptNode.src = 'https://bce.bdstatic.com/fe-monitor/latest/fe-monitor.min.js';
        document.body.appendChild(scriptNode);
        scriptNode.onload = () => {
            // 区分线上环境和测试环境
            const domain = (window?.location?.host || '').indexOf('.cloud.baidu.com') !== -1 ? '' : 'test';
            window.Monitor.default.init({
                appTag: `yijian-${domain || 'online'}`, // 当前应用的标签，用于区分埋点数据来自哪个应用的哪个环境
                api: `https://cloud${domain}.baidu.com/img/bh.gif`,
                baiduId: getCookie('BAIDUID') || '',
                accountId: (getCookie('bce-session') || '').slice(0, 32)
            });
        };
    } catch (error) {
        console.log(error);
    }
}
