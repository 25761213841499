/*
 * @Author: zhoupengfei03
 * @Description: 设置根元素字体大小
 */

// 计算根元素字体大小
const setRootFontSize = () => {
    const rootFontSize = 120;
    const rootWidth = 1200;
    const deviceWidth = Math.min(document.documentElement.clientWidth, 1800);
    document.documentElement.style.fontSize = deviceWidth * rootFontSize / rootWidth + 'px';
};

// 初始化
setRootFontSize();

// 监听窗口大小变化
window.onresize = () => {
    setRootFontSize();
};