/**
 * @file redux/user
 * @author zhongziyan
 */
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isLogin: false
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        updateLoginStatus: (state = initialState.isLogin, action) => {
            state.isLogin = action.payload;
        },
    }
});

export const isLogin = state => state.userSlice.updateLoginStatus;

export const {updateLoginStatus} = userSlice.actions;
