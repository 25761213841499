/**
 * @description 路由后置导航守卫
 * @author guowei26
 */

// 切换路由，滚动条跳滑到顶部
export const scrollToTop = () => {
    // console.log(from, to);
    window.scrollTo(0, 0);
};
