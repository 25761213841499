/*
 * @Author: liuyan45
 * @Date: 2023-03-16 15:48:31
 * @LastEditTime: 2023-07-11 17:09:04
 * @LastEditors: liuyan45
 * @FilePath: /ivap-fe/src/redux/menu.slice.js
 * @Description: 菜单栏相关数据
 */
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    // 菜单标题
    title: '',
    // 菜单栏数据
    menu: [],
    // 当前选中菜单
    currentMenu: {},
    // 菜单栏下拉框显示隐藏
    hideMenuSelect: false,
    // 顶部导航显示隐藏
    hideHeaderNav: false,
    // 侧导航显示隐藏
    hideSideNav: false,
    // 侧导航展开收起
    collapse: false,
    // 侧导航下拉框的显示和隐藏
    hideSiderNavSelect: false
};

export const menuSlice = createSlice({
    name: 'menuSlice',
    initialState,
    reducers: {
        updateTitle: (state, actions) => {
            state.title = actions.payload;
        },
        updateMenuIcon: (state, actions) => {
            state.menuIcon = actions.payload;
        },
        updateMenu: (state, actions) => {
            state.menu = actions.payload;
        },
        updateCurrentMenu: (state, actions) => {
            state.currentMenu = actions.payload;
        },
        updateHeaderStatus: (state, actions) => {
            state.hideHeaderNav = actions.payload;
        },
        updateMenuSelectStatus: (state, actions) => {
            state.hideMenuSelect = actions.payload;
        },
        updateSideNavStatus: (state, actions) => {
            state.hideSideNav = actions.payload;
        },
        updateCollapse: (state, actions) => {
            state.collapse = actions.payload;
        },
        updateSiderNavSelectStatus: (state, actions) => {
            state.hideSiderNavSelect = actions.payload;
        }
    }
});

export const menu = state => state.menuSlice.menu;

export const currentMenu = state => state.menuSlice.currentMenu;

export const hideMenuSelect = state => state.menuSlice.hideMenuSelect;

export const hideHeaderNav = state => state.menuSlice.hideHeaderNav;

export const hideSideNav = state => state.menuSlice.hideSideNav;

export const collapse = state => state.menuSlice.collapse;

export const menuTitle = state => state.menuSlice.title;

export const menuIcon = state => state.menuSlice.menuIcon;

export const hideSiderNavSelect = state => state.menuSlice.hideSiderNavSelect;

export const {
    updateTitle,
    updateMenuIcon,
    updateMenu,
    updateCurrentMenu,
    updateMenuSelectStatus,
    updateHeaderStatus,
    updateSideNavStatus,
    updateCollapse,
    updateSiderNavSelectStatus
} = menuSlice.actions;
