/*
 * @Author: zhoupengfei03 zhoupengfei03@baidu.com
 * @Date: 2023-04-10 16:53:49
 * @LastEditors: zhoupengfei03 zhoupengfei03@baidu.com
 * @LastEditTime: 2023-06-12 11:14:53
 * @FilePath: /IVAP/baidu/acg-industry-fe/ivap-fe/src/utils/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import {clearCookie} from './cookie';
// 登录
export function toLogin(idaasUrl) {
    window.location.replace(idaasUrl + '?redirect=' + window.location.href);
}
// 登出
export function toLogout(idaasUrl) {
    window.location.replace(idaasUrl + '?redirect=' + window.location.href);
}
/**
 * 登录失效后处理重定向
 * @param {*} response
 */
export const handleRedirect = response => {
    const result = response?.message?.global || {};
    const {redirectLogin = null} = JSON.parse(result);
    if (redirectLogin) {
        localStorage.clear();
        sessionStorage.clear();
        toLogin(redirectLogin);
    }
};

// 鉴权失败时nginx配置重定向
export const handleSignatureRedirect = (action = 'login') => {
    if (window.location.href?.indexOf('?redirect=') > -1) {
        return;
    }
    clearCookie();
    localStorage.clear();
    sessionStorage.clear();
    if (window.location.hostname?.indexOf('idaas.bce.baidu.com') > -1) {
        const redirectLogin = window.location.protocol + '//' + window.location.hostname + `/${action}?redirect=` + window.location.href;
        window.location.replace(redirectLogin);
    } else {
        const redirectLogin = window.location.origin + `/${action}?redirect=` + window.location.href;
        window.location.replace(redirectLogin);
    }
};
