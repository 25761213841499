/**
 * @file 状态收口文件
 * @author guowei26
 */

import {configureStore} from '@reduxjs/toolkit';
import {menuSlice} from './menu.slice.js';
import {uploadSlice} from './upload.slice.js';
import {userSlice} from './user.slice.js';

export const rootReducer = {
    menuSlice: menuSlice.reducer,
    uploadSlice: uploadSlice.reducer,
    userSlice: userSlice.reducer,
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
});
