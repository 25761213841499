/*
 * @Author: zhoupengfei03
 * @Description: jsonp方案获取bce cookie
 */

import $ from 'jquery';

// iam flag
const iamUrlflags = [
    'yijian-vistudio.cloud',
    'yijian-vistudio.baidu-int'
];

// 是否是iam
export function isIam() {
    const url = window.location.href;
    for (let i = 0, len = iamUrlflags.length; i < len; i++) {
        if (url.indexOf(iamUrlflags[i]) > -1) {
            return true;
        }
    }
    return false;
}

// 是否是isOnline
export function isOnline() {
    const url = window.location.href;
    return url.indexOf('yijian-vistudio.cloud') > -1;
}

const targetMod = isOnline() ? 'https://bce.baidu.com' : 'https://bcetest.baidu.com';

// 获取cookie
export function getJsonpCookie(name, cookieText = '') {
    /* eslint-disable */
    cookieText = document.cookie;

    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    const arr = cookieText.match(reg);

    let ret = '';

    if (arr) {
        ret = decodeURIComponent(arr[2]);
    }

    return ret;
}

// 设置cookie
export function setJsonpCookie(name, value, options) {
    if (typeof options.expires === 'number') {
        const days = options.expires;
        const expiresTime = new Date();
        expiresTime.setDate(expiresTime.getDate() + days);
        options.expires = expiresTime;
    }

    return (document.cookie = [
        name,
        '=',
        options.notEncodeValue ? value : encodeURIComponent(value),
        options.expires ? '; expires=' + options.expires.toUTCString() : '',
        options.path ? '; path=' + options.path : '',
        options.domain ? '; domain=' + options.domain : '',
        options.secure ? '; secure' : '',
    ].join(''));
}

export function removeJsonpCookie(name, options) {
    if (getJsonpCookie(name) !== undefined) {
        setJsonpCookie(name, '', {
            ...options,
            expires: -1,
        });
        return true;
    }

    return false;
}

// jsonp获取到bce cookie,并将cookie赋值给cloud的cookie
export function jsonp(url) {
    let pageUrl = targetMod + url;
    return new Promise((resolve, reject) => {
        $.ajax({
            type: 'get',
            url: pageUrl,
            dataType: 'jsonp',
            jsonp: 'callback',
            success: data => {
                const host = window.location.hostname;
                let subdomain = host;
                try {
                    subdomain = window.location.hostname.split('.').slice(-2).join('.');
                } catch (error) {
                    console.log(error);
                }
                const {accountId = '', displayName = ''} = data.result;
                // 需要同步的cookies值
                let syncCookies = {};
                if (accountId && data.result.cookies) {
                    syncCookies = {
                        accountId,
                        displayName,
                        ...data.result.cookies,
                    };
                }

                /* eslint-disable */
                for (let key in syncCookies) {
                    /* eslint-enable */
                    if (!syncCookies.hasOwnProperty(key)) {
                        continue;
                    }
                    // 过一段儿时间，同步 tracking cookie 的逻辑就可以去掉了
                    // 这里主要是保证老用户的 tracking cookie 从 bce 同步 cloud
                    const isTracking =
                        key === 'CAMPAIGN_TRACK'
                        || key === 'CAMPAIGN_TRACK_TIME';
                    // 这三个字段用来鉴权的，转义了就无法鉴权了
                    const isNotEncode =
                        key === 'bce-session'
                        || key === 'bce-user-info'
                        || key === 'bce-ctl-client-cookies';
                    if (isTracking && getJsonpCookie(key)) {
                        // 如果已经有了，就不需要覆盖了
                        continue;
                    }
                    const value = syncCookies[key];
                    if (value) {
                        if (isTracking) {
                            setJsonpCookie(key, value, {
                                expires: 90,
                                path: '/',
                                domain: `.${subdomain}`,
                            });
                        } else {
                            setJsonpCookie(key, value, {
                                path: '/',
                                domain: `.${subdomain}`,
                                notEncodeValue: isNotEncode,
                            });
                        }
                    } else if (!isTracking) {
                        removeJsonpCookie(key, {path: '/', domain: `.${subdomain}`});
                    }
                }
                resolve(data);
            },
            error: data => {
                reject(data);
            }
        });
    });
}