/*
 * @Author: liuyan45
 * @Date: 2023-04-03 16:37:10
 * @LastEditTime: 2023-04-03 17:35:28
 * @LastEditors: liuyan45
 * @FilePath: /aiqa-fe/src/redux/upload.slice.js
 * @Description: 上传数据
 */

import {createSlice} from '@reduxjs/toolkit';
const initialState = {
    uploadParams: {
        visible: false,
        list: []
    },
    uploadTaskQueue: [],
    uploadStepShow: false
};

export const uploadSlice = createSlice({
    name: 'uploadSlice',
    initialState,
    reducers: {
        updateUploadParams: (state = initialState.uploadParams, action) => {
            state.uploadParams = action.payload;
        },
        updateUploadTaskQueue: (state = initialState.uploadTaskQueue, action) => {
            state.uploadTaskQueue = action.payload;
        },
        updateUploadStepShow: (state = initialState.uploadStepShow, action) => {
            state.uploadStepShow = action.payload;
        }
    }
});

export const uploadParams = state => state.uploadSlice.uploadParams;
export const uploadTaskQueue = state => state.uploadSlice.uploadTaskQueue;
export const uploadStepShow = state => state.uploadSlice.uploadStepShow;

export const {
    updateUploadParams,
    updateUploadTaskQueue,
    updateUploadStepShow
} = uploadSlice.actions;
