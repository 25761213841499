/*
 * @Author: liuyan45
 * @Date: 2022-01-13 19:11:13
 * @LastEditTime: 2022-01-13 19:12:19
 * @LastEditors: liuyan45
 * @FilePath: /blue-cli/src/utils/cookie.js
 * @Description: cookie工具
 */

/** * 获取cookie * */
export const getCookie = name => {
    const cookies = document.cookie.split(';');
    const target = cookies.find(cookie => cookie.split('=')[0].trim() === name);
    if (target) {
        return decodeURIComponent(target.split('=')[1]);
    }

    return null;
};
/** * 设置cookie * */
export function setCookie(name, value, level) {
    const effectDomain = level ? '.' + document?.domain?.split('.')?.slice(-level)?.join('.') : document?.domain;
    document.cookie = name + '=' + escape(value) + '; path=/; domain=' + effectDomain;
}
/** * 去除cookie * */
export function removeCookie(name) {
    const value = getCookie(name);
    if (value) {
        const exp = new Date();
        exp.setTime(exp.getTime() - 10000);
        document.cookie =
            name + '=' + value + '; expires=' + exp.toUTCString() + '; path=/';
    }
}
/** * 清除所有cookie * */
export function clearCookie() {
    const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (let i = keys.length; i--;) {
            // 清除当前域名下的,例如：m.kevis.com
            document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();
            // 清除当前域名下的，例如 .m.kevis.com
            document.cookie =
                keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();
            // 清除一级域名下的或指定的，例如 .kevis.com
            document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();
        }
    }
}
