/*
 * @Author: zhoupengfei03
 * @Description: 一见官网首页路由
 */

import React from 'react';
import isMobile from 'is-mobile';

import {RouterItem} from './type';

const pc = React.lazy(() => import('@/pages/Home'));
const mobile = React.lazy(() => import('@/pages/Mobile'));

const routers: RouterItem[] = [
    {
        path: '/',
        title: '一见官网',
        component: isMobile() ? mobile : pc,
        hideHeaderNav: true,
        hideSideNav: true
    }
];

export default routers;