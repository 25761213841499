/*
 * @Author: zhoupengfei03
 * @Date: 2021-12-27 17:14:23
 * @LastEditTime: 2023-08-23 15:04:18
 * @LastEditors: zhoupengfei03 zhoupengfei03@baidu.com
 * @Description: 项目路由配置，所有的路由总出口
 */
import {matchPath} from 'react-router-dom';

import home from './home';
import {RouterItem} from './type';
// 当内容较多时，可以单独拆分为文件，在此入口文件中引入

// 路由匹配优先级按照数组中元素的path匹配顺序，先匹配到的则会被使用
const routers: RouterItem[] = [
    ...home,
];
export default routers;
export * from './type';

// 根据当前页面路径获取对应路由配置
export const getTargetRouteConfig = (pathName: string): RouterItem => {
    const findMatchRoute = (path?: string) => matchPath(pathName, {path, exact: true, strict: true});
    let targetRoute: RouterItem = {hideHeaderNav: false, hideSideNav: false};
    const findRightRoute = (routeList: RouterItem[] = []) => {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < routeList.length; i++) {
            const route = routeList[i];
            const isRight = findMatchRoute(route.path);
            const isExistChildren = route.routes && route.routes.length > 0;
            if (isRight) {
                targetRoute = route;
                break;
            }
            if (isExistChildren) {
                findRightRoute(route.routes);
            }
        }
    };
    findRightRoute(routers);
    return targetRoute;
};
