/*
* @Author: liuyan45
* @Date: 2021-12-29 18:52:48
 * @LastEditTime: 2021-12-29 18:59:10
 * @LastEditors: liuyan45
 * @FilePath: /blue-cli/src/components/Loading/index.js
* @Description: Loading
*/

import './index.less';

export default props => {
    return (
        <div className={'preloader'}>
            <div className="preloader-inner">
                <div className="preloader-icon">
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
};