/**
 * @file NotFound Page
 * @author zhongziyan
 */

import {notFound} from '@/assets/images';

import './index.less';

export default () => {
    return (
        <div
            className="not-found"
        >
            <img
                src={notFound}
            />
            <div className="title">
                <h2>网站维护中...</h2>
                <p>为了让您更好的使用系统，我们正在对服务器进行升级，升级期间暂时无法访问。</p>
                <p>给您带来的不便，敬请谅解！</p>
            </div>
        </div>
    );
};
